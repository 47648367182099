<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
    <div style="">
        <div class="text-center">
            <v-dialog
                    v-model="dialog"
                    persistent
                    width="50"
            >
                <img src='../../assets/loader_spinner.gif' style="width: 50px; ">

            </v-dialog>
        </div>
        <v-row>
            <v-col>
                <v-container class="my-5">
                    <v-row class="px-3">
                        <v-col cols="0" md="2">
                            <admin-main-menu></admin-main-menu>
                        </v-col>
                        <v-col cols="12" md="9">
                            <v-row>
                                <v-col cols="12" class="sub-title-3">
                                    SMS 설정
                                </v-col>
                                <table class="table-left-s">
                                    <colgroup>
                                        <col style="width:25%"/>
                                        <col style="width:75%"/>
                                    </colgroup>
                                    <tr>
                                        <th class="th">접속키</th>
                                        <td class="td">
                                            <input id="accessKey" v-model="smsInfo.accessKey" style="width: 250px;">

                                        </td>
                                    </tr>
                                    <tr>
                                        <th class="th">비밀키</th>
                                        <td class="td">
                                            <input id="secretKey" v-model="smsInfo.secretKey" style="width: 250px;">

                                        </td>
                                    </tr>
                                    <tr>
                                        <th class="th">서비스ID</th>
                                        <td class="td">
                                            <input id="serviceId" v-model="smsInfo.serviceId" style="width: 250px;">
                                        </td>
                                    </tr>
                                    <tr>
                                        <th class="th">발신번호</th>
                                        <td class="td-phone">
                                            <input id="fromTel"
                                                   v-model="smsInfo.fromTel"
                                                   style="width: 250px;"
                                                   maxlength="13"
                                                   @keyup="autoHyphen()">
                                            *고객에게 전송되는 전화번호

                                        </td>
                                    </tr>
                                    <tr>
                                        <th class="th">관리자번호</th>
                                        <td class="td-phone">
                                            <input id="toTel"
                                                   v-model="smsInfo.toTel"
                                                   style="width: 250px;"
                                                   maxlength="13"
                                                   @keyup="autoHyphen1()">
                                        </td>
                                    </tr>
                                </table>
                            </v-row>
                            <!--<v-row>
                                <v-col>
                                    <v-col cols="12" class="sub-title-3 mt-4">
                                        전송방법
                                    </v-col>

                                    <table class="table-left-s">
                                        <colgroup>
                                            <col style="width:25%"/>
                                            <col style="width:75%"/>
                                        </colgroup>
                                        <tr>
                                            <th class="th pa-2">예약신청시</th>
                                            <td class="td">
                                                <input type="checkbox" id="checkbox1" v-model="smsInfo.isResvTo"
                                                       class="checkbox-custom-admin">
                                                <label for="checkbox1" class="label-custom-admin">손님에게 전송</label>
                                                <input type="checkbox" id="checkbox2" v-model="smsInfo.isResvFrom"
                                                       class="checkbox-custom-admin">
                                                <label for="checkbox2" class="label-custom-admin">관리자에게 전송</label>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th class="th pa-2">예약완료시</th>
                                            <td class="td">
                                                <input type="checkbox" id="checkbox3" v-model="smsInfo.isResvComp"
                                                       class="checkbox-custom-admin">
                                                <label for="checkbox3" class="label-custom-admin">손님에게 전송</label>
                                                *체크하시면 예약관리에서, 입금완료로 변경시 전송됩니다.
                                            </td>
                                        </tr>
                                        <tr>
                                            <th class="th pa-2">환불완료시</th>
                                            <td class="td">
                                                <input type="checkbox" id="checkbox4" v-model="smsInfo.isResvCancel"
                                                       class="checkbox-custom-admin">
                                                <label for="checkbox4" class="label-custom-admin">손님에게 전송</label>
                                                *체크하시면 예약관리에서, 환불완료로 변경시 전송됩니다.
                                            </td>
                                        </tr>
                                    </table>

                                </v-col>
                            </v-row>-->
                            <v-row>
                                <v-col>
                                    <v-col cols="12" class="sub-title-3 mt-4">
                                        메세지 내용
                                    </v-col>
                                    <v-col cols="12">
                                        예약자명: __NAME__  ,  객실명: __ROOM__  ,  예약날짜: __DATE__  ,  고객전화: __USHP__
                                    </v-col>

                                    <v-row>
                                        <v-col
                                                v-for="(item,n) in smsList"
                                                :key="n"
                                                class="d-flex child-flex"
                                                cols="12" sm="6">
                                            <v-card class="borderPhoto pa-4">

                                                <v-card-title class="text-h6 px-0 py-2" style="display: block;">
                                                    <input type="checkbox" v-model="item.useYn"
                                                           class="checkbox-custom-admin">
                                                    {{item.smsName}}
                                                    <!--<v-btn
                                                            color="primary"
                                                            class="mx-4 white&#45;&#45;text center-block float-right"
                                                            small
                                                            @click="testSend(item)"
                                                    >
                                                        테스트
                                                    </v-btn>-->
                                                </v-card-title>

                                                <textarea v-model="item.smsContent" style="resize: none;"
                                                          id="textarea"
                                                          class="textarea1"
                                                          placeholder="남기실 말씀을 적어 주세요."
                                                          @input="input($event.target.value, item)">></textarea>

                                                <v-card-subtitle>
                                                    {{item.smsByte}} / 90 byte
                                                </v-card-subtitle>
                                            </v-card>

                                        </v-col>
                                    </v-row>

                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col>
                                    <v-btn
                                            color="primary"
                                            class="mx-4 white--text center-block"
                                            small
                                            @click="saveInfo(2)"
                                    >
                                        저장
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                </v-container>
            </v-col>
        </v-row>


    </div>
</template>

<script>
    import AdminMainMenu from '../../components/AdminMainMenu'
    export default {
        name: 'AdminSmsInfo',
        components: {
            AdminMainMenu
        },
        data: () => ({
            dialog: false,
            smsInfo: {
                smsId: "",
                accessKey: "",
                secretKey: "",
                serviceId: "",
                fromTel: "",
                toTel: "",
                useYn: "",
                regDate: "",
                lstModPrs: "",
                lstModDate: "",
            },
            smsList: [],


        }),
        created: function () {
            // 맨위로
            window.scrollTo(0, 0);
            //this.$isAdminCheck(this.$store.state.admin.isLogin);
            //this.$isAuthCheck(this.$getCookie("AUTH"));
            this.getData();
            this.init();


        },
        methods: {
            getData() {
                return this.$store.dispatch("sms/getSmsInfo", {smsId: '1'})
                    .then((resp) => {
                        setTimeout(() => {

                            this.smsInfo = resp.message;
                            this.smsList = this.smsInfo.smsList;

                            // 핸드폰 번호 변경
                            this.smsInfo.fromTel = this.$setPhoneNum(this.smsInfo.fromTel);

                            // 핸드폰 번호 변경
                            this.smsInfo.toTel = this.$setPhoneNum(this.smsInfo.toTel);

                            this.setSmsList();


                        }, 300)
                    })
                    .catch((err) => {
                    })

            },
            setSmsList() {

                if (this.smsList.length > 0) {

                    for (let i = 0; this.smsList.length > i; i++) {
                        if (this.smsList[i].useYn === '1' || this.smsList[i].useYn === 'true') {
                            this.smsList[i].useYn = true;
                        } else {
                            this.smsList[i].useYn = false;
                        }
                        //this.getByteLength1(this.smsList[i].smsContent);
                        //this.smsList[i].smsByte = this.getByteLength(this.smsList[i].smsContent,0,0,0)
                        this.smsList[i].smsByte = this.getByteLength1(this.smsList[i].smsContent);
                    }

                }

            },
            setByteLength(item){

                for (let i = 0; this.smsList.length > i; i++) {
                    if(this.smsList[i].smsInfoId === item.smsInfoId){

                        this.smsList[i].smsByte = this.getByteLength1(this.smsList[i].smsContent, 0,0,0);

                        break;
                    }


                }

            },
            getByteLength1(str){

                var strLen = str.length;
                var cnt = 0;
                var oneChar = "";

                for(var ii=0;ii<strLen;ii++){
                    oneChar = str.charAt(ii);
                    if(escape(oneChar).length > 4) {
                        cnt += 2;
                    } else {
                        cnt++;
                    }
                }
                return cnt;

            },
            saveInfo() {
                this.dialog = true;

                this.smsInfo.fromTel = this.smsInfo.fromTel.replace(/-/g, "");
                this.smsInfo.toTel = this.smsInfo.toTel.replace(/-/g, "");

                return this.$store.dispatch("sms/updateSms", this.smsInfo)
                    .then((resp) => {
                        setTimeout(() => {
                            alert("저장완료");
                            this.dialog = false;
                            this.getData();

                        }, 500)
                    })
                    .catch((err) => {
                        this.dialog = false;
                    })

            },
            testSend(item){

                this.dialog = true;

                item.resvId = 1;

                return this.$store.dispatch("sms/sendSms", item)
                    .then((resp) => {
                        setTimeout(() => {
                            alert("전송완료");
                            this.dialog = false;

                        }, 500)
                    })
                    .catch((err) => {
                        this.dialog = false;
                    })
            },
            menuClick(route) {
                location.href = route;
                //this.$router.push({path: route});
            },
            init() {
                this.resize()
            },
            input(value, item) {

                this.$emit('input', value);
                this.resize();
                this.setByteLength(item);
            },
            resize($event) {
                if (!this.autoresize) return;
                this.$textarea.style.height = '1px';
                this.$textarea.style.height = `${this.$textarea.scrollHeight + 12}px`
            },
            autoHyphen(val) {
                this.smsInfo.fromTel = this.smsInfo.fromTel
                    .replace(/[^0-9]/g, '')
                    .replace(/^(\d{0,3})(\d{0,4})(\d{0,4})$/g, "$1-$2-$3").replace(/(\-{1,2})$/g, "");
            },
            autoHyphen1(val) {
                this.smsInfo.toTel = this.smsInfo.toTel
                    .replace(/[^0-9]/g, '')
                    .replace(/^(\d{0,3})(\d{0,4})(\d{0,4})$/g, "$1-$2-$3").replace(/(\-{1,2})$/g, "");
            },

        },
        mounted() {
            this.$nextTick(() => {

            })
        },
        computed: {
        },
    }
</script>

<style>

</style>
